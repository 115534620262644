td.search-order-actions {
  .search-order-link {
    margin: 0;
    padding: 0;
    display: inline-block;
    svg {
      path {
        stroke: $icon-stroke;
      }
    }
  }
  .file-icon {
    path {
      stroke-width: 0px;
    }
  }

  &:focus {
    border: none;
    @include outline-dark;
    text-decoration: none;
  }

  svg,
  img {
    display: block;
  }
}
