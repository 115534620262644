.confirmation-checkbox-container {
  padding: 24px;
  margin-top: 16px;
  border-radius: 6px;
  background-color: #f7f7f7;
  width: 100%;

  fieldset,
  p {
    margin: 0;
  }

  .checkbox {
    align-items: flex-start;
    label {
      @include text-body--large;
      margin-top: -1px;
      margin-bottom: 0;
    }
  }

  &.is-error {
    input:not(:checked) {
      border: 2px solid $red-base;
    }

    .checkbox label {
      color: $red-base;
    }
  }
}

.confirmation-checkbox-container.is-error {
  span.error-message {
    display: none;
  }
}
