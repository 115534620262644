.upload-summary-view-container {
  .discarded-participants {
    .download-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      span {
        @include text-title--small;
      }

      button {
        @include text-label--medium;
        display: flex;
        align-items: center;
        color: $primary-base;

        &:hover {
          color: $primary-dark;
        }

        svg {
          width: 18px;
          margin-right: 8px;

          path {
            fill: $primary-base;
          }
        }
      }
    }
  }
}
