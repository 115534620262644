.notification {
  display: flex;
  margin-bottom: 2.375rem;
  padding: 12px 14px;
  @include border-radius;
  @include text-title--small;
  line-height: normal;
  color: $grey-6;

  svg {
    overflow: visible;
    padding-right: 6px;
    vertical-align: text-top;
    margin-top: -2px;
  }

  &--tight {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--badge {
    width: fit-content;
  }

  &.success {
    background: $indigo-3;
    border: 1px solid $accent-green;
    > svg {
      path {
        fill: $primary-base;
      }
    }
  }
  &.warning {
    background: $amber-1;
    border: 1px solid $orange-7;

    > svg {
      path {
        fill: $orange-7;
      }
    }
  }
  &.informational {
    background: $blue-1;
    border: 1px solid $blue-7;

    > svg {
      path {
        fill: $blue-6;
      }
    }
  }
  &.error {
    background: $red-1;

    > svg {
      path {
        fill: $red-6;
      }
    }
  }
}

.error-scroll-offset {
  scroll-margin: 150px;
}

.banner-notification {
  margin: 30px 50px;
  margin-bottom: -30px;
}
