.file-input {
  .drag-n-drop-zone {
    border: 1px dashed $grey-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 4.75rem;
    position: relative;
    @include border-radius($input-border-radius);

    &.isError {
      border: 1px dashed $red-6;
    }

    .instructions-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .subtext p {
        @include text-body--small;
        margin-bottom: 0;
      }

      .drop-text {
        margin-bottom: 0;
      }
    }
  }

  .error-message {
    padding-top: 0.325rem;
  }

  .file-name-container:not(:first-of-type) {
    margin-top: 1.25rem;
  }
}
