.no-table-results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  background: $white-base;

  h2,
  h3,
  h4 {
    @include text-headline--medium;
  }

  p {
    @include rem(max-width, 1250px);
    @include text-body--large;
    padding: 0 315px 0 315px;
    margin-bottom: 0px;
    &:last-of-type {
      padding-bottom: 36px;
    }
    text-align: center;
  }
}
