#start-new-rollover-modal {
  @include rem(width, $modal-width);

  ul {
    @include rem(margin-left, 20px);
    @include rem(padding-top, 12px);
    li {
      list-style: disc;
      @include rem(margin-bottom, 20px);
    }
  }
}
