.accordion {
  border-bottom: $border-style;
  margin-bottom: 40px;

  .accordion__item + .accordion__item {
    margin-top: 30px;
  }

  .accordion__button {
    cursor: pointer;
    margin-top: 12px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-top: $border-style;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion__button:hover {
    background-color: transparent;
  }

  .accordion__button:after {
    content: '';
    height: 20px;
    width: 20px;
    background: transparent url('../images/utility-icons/plus.svg') 0% 1px
      no-repeat padding-box;
  }

  .accordion__button[aria-expanded='true']::after,
  .accordion__button[aria-selected='true']::after {
    background: transparent url('../images/utility-icons/minus.svg') 0 8px
      no-repeat padding-box;
  }

  .accordion__heading {
    margin-bottom: 0;
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    margin: 12px 0;
    animation: fadein 0.35s ease-in;
  }

  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
