.flow-button-container {
  display: grid;
  gap: 0;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid $grey-4;

  a,
  button {
    margin-bottom: 0;
    padding: 5px 15px;
  }

  .button-primary {
    padding-left: 24px;
    padding-right: 16px;
  }

  .flow-button-left,
  .flow-button-right {
    justify-content: space-between;
    display: flex;
    padding: 0 14px;
  }

  .flow-button-left {
    padding-left: 10px;
  }

  .flow-button-right {
    padding-right: 10px;
  }

  .icon-continue {
    margin-left: 5px;
    path:last-of-type {
      fill: $white-base;
    }
  }
}
