#confirm-modal {
  &.exit-confirm-prompt {
    width: 400px;
  }

  .confirm-modal-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;

    #cancel-action {
      text-decoration: none;
      color: $primary-base;
      margin-top: 0;

      &:hover {
        color: $primary-dark;
      }
    }
  }
}
