.disclosure {
  div[aria-level] {
    margin-bottom: 0;
  }

  .disclosure-button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    margin: 0 12px 0 -12px;
    width: 100%;
    cursor: pointer;
    border: 2px solid transparent;

    &:focus-visible {
      border: 2px solid $primary-dark;
    }
  }

  svg {
    path {
      stroke: $icon-stroke;
    }
  }

  .disclosure-content {
    margin: 12px 0;
  }
}
