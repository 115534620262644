$status-tracker-grey: #b2b2b2;

.workflow-layout-wrapper {
  @include rem(width, 1250px);
  margin: 32px auto 0 auto;
  display: flex;
  flex-direction: column;
  // #header-action-area height = 62px
  // .workflow-layout-wrapper top margin = 32px
  // .flow-button-container height = 75px
  min-height: calc(100vh - 169px);

  .card {
    &.full-height {
      flex-grow: 1;
      padding: 40px 40px 120px 40px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .form-section {
    margin-bottom: 40px;
    .plan-details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    .remove-btn {
      margin-top: 24px;
      align-items: center;
      margin-bottom: 64px;
      button {
        width: 100%;
      }
    }
    .add-btn {
      margin-bottom: 24px;
      button {
        svg {
          margin-right: 10px;
        }
      }
    }
    .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      input {
        width: 350px;
      }
    }
    .button-handler {
      margin-top: 24px;
      align-items: center;
      margin-bottom: 64px;
      button {
        width: 100%;
      }
    }
    .plan-details-title {
      border-top: $border-style;
      display: flex;
      flex-direction: row;

      h3 {
        margin-right: 186px;
        margin-top: 10px;
      }
    }

    &--with-top-border {
      border-top: 1px solid $grey-4;
      padding-top: 14px;
    }

    &--no-bottom-margin {
      margin-bottom: 0;
    }

    &--new-spreadsheet {
      .notification {
        svg {
          &.new {
            padding-left: 8px;
          }
        }

        .link-text {
          @include text-label--medium;
          color: $primary-base;
          margin-bottom: 0;
        }
      }
    }
  }

  .workflow-status-tracker-wrapper {
    margin-bottom: 8px;

    .workflow-status-tracker {
      display: flex;

      li {
        width: 100%;
        margin: 0 5px;
      }
    }
  }
  .category-status-container {
    margin-bottom: 30px;
    span {
      display: block;
      @include text-title--medium;
      color: $grey-6;
      margin-bottom: 0;
    }
    .current-stage {
      color: $primary-base;
    }

    label {
      @include text-label--medium;
      display: block;
      margin-bottom: 2px;

      &.is-current {
        color: $primary-base;
      }

      &.is-upcoming {
        color: $grey-4;
      }
    }
  }

  &.workflow-search-services {
    .card {
      #search-template {
        @include text-title--medium;
        text-decoration: none;
        color: $primary-base;
      }
    }
    .form-header {
      @include rem(margin-bottom, 12px);

      h3 {
        @include text-headline--small;
        @include rem(margin-bottom, 32px);
        @include rem(padding-top, 12px);
        border-top: $border-style;
      }
      p {
        margin-top: -25px;
        margin-bottom: 30px;
      }
    }

    .workflow-card-container {
      margin-bottom: 20px;

      .upload-file {
        width: 100%;
      }

      hr {
        margin: 1.875rem 0;
      }

      .card {
        &:not(:first-child) {
          margin-top: 1.25rem;
        }

        width: 53.125rem;
        height: max-content;
        background: #fff;
        padding: 1.5rem;
        box-shadow: 0px 1px 5px #15223229;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &:not(.workflow-search-services) {
    .form-header {
      @include rem(margin-bottom, 12px);

      h3 {
        @include text-headline--small;
        @include rem(margin-bottom, 32px);
        @include rem(padding-top, 12px);
        border-top: $border-style;
      }
    }

    .plan-mapping.error {
      .notification.error {
        margin-bottom: 12px;
      }
    }

    .workflow-card-container {
      .plan-content {
        .searchable-select {
          margin-bottom: 8px;
        }
        .link-text {
          @include text-body--small;
          display: block;
        }
      }
    }
  }

  .plan-mapping {
    dl {
      border-top: $border-style;
      padding: 24px 0;
    }

    .row {
      fieldset {
        margin-bottom: 0;
      }

      a.link-text {
        display: inline-block;
        margin: 8px 0 32px 0;
        text-decoration: none;
      }
    }

    .row:last-child {
      fieldset {
        margin-bottom: 0;
      }
    }

    &.error {
      .add-services-agreement {
        width: 100%;
      }
    }

    // search select
    .mapping-select__menu-list {
      .select-group-label {
        border-top: $border-style;
        padding-top: 0.625rem;
      }

      > div:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.workflow-status-tracker-wrapper {
  .workflow-status-tracker {
    li {
      margin-bottom: 1.3125rem;
      position: relative;
      @include text-label--small;
      line-height: 2.15; // This override is necessary for the lines to connect properly
    }
  }

  .category-status-container {
    color: $status-tracker-grey;

    &.current {
      color: $primary-base;

      .visual-border {
        background-color: $primary-base;
      }
    }

    &.complete {
      color: $grey-7;

      .visual-border {
        background-color: $grey-7;
      }
    }

    .label {
      color: $grey-6;
    }

    .visual-border {
      height: 4px;
      border-radius: 2px;
      background-color: $grey-4;
      width: 100%;
    }
  }
}

.form-card-content {
  height: 100%;
}

.flow-section-headers {
  h1 {
    margin-bottom: 1.75rem;
  }
}

.workflow-header-container {
  margin-bottom: 27px;

  h2 {
    margin-bottom: 7px;
  }
}
