.order-details-container {
  dl {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 50px;
  }

  .status {
    padding: 0px;
  }

  dt {
    margin-bottom: 16px;
  }

  dd {
    margin-bottom: 0;
  }
}

.order-details-column {
  div {
    @include rem(margin-bottom, 10px);
  }
  .status-text {
    .tooltip {
      margin-left: 0;
    }
  }
}

.order-details-actions {
  margin-top: 20px;

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        margin: 0;
      }
    }
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .left-top {
    position: absolute;
    top: 25px;
  }

  h1 {
    @include text-headline--medium;
    margin-top: 20px;
  }

  > div {
    display: flex;
  }

  button {
    display: flex;
    vertical-align: middle;
    margin: 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .divider {
    @include rem(height, 28px);
    @include rem(width, 1px);
    background-color: $grey-3;
    position: relative;
    top: 1px;
  }
}

.order-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  & + hr {
    @include rem(margin-bottom, 30px);
    border-bottom: 1px solid $grey-2;
  }

  .title {
    @include text-headline--small;
  }

  .company-name {
    @include text-title--medium;
    color: $grey-5;
  }
}
