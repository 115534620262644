.resources {
  .resources-header {
    margin-bottom: 32px;

    h1 {
      margin-bottom: 10px;
    }
  }

  .resources-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px 32px;
    justify-content: start;
    width: 100%;

    p {
      @include text-body--large;
    }

    .resources-card-container {
      border: $border-style;
      background-color: white;
      border-radius: 12px;
      box-shadow: 1px 2px 4px rgba(61, 61, 58, 0.2);
      padding: 15px;
      min-width: 370px;
      min-height: 444px;
      display: flex;
      flex-direction: column;

      .resources-card-video {
        display: flex;
        justify-content: center;
        margin: 0;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        height: auto;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

        .react-player {
          position: absolute;
          top: 0;
          left: 0;
        }

        .react-player__shadow {
          background-color: $button-disabled-bg-color;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          .react-player__play-icon {
            margin-top: 10px;
            margin-left: 13px;
            border-style: solid;
            border-color: transparent transparent transparent
              rgba(0, 0, 0, 0.35);
            border-width: 8px 0 8px 12px;
          }
        }
      }

      .resources-card-header {
        margin: 15px 0 8px;

        .youtube-link {
          margin: 0;
          text-decoration: none;
          color: $primary-base;
          @include text-title--medium;
        }
      }

      .resources-card-content {
        margin-bottom: 24px;
        flex-basis: 100%;
      }

      .resources-card-footer {
        margin: 0;
        p {
          display: inline;
          padding-right: 8px;
        }

        p:not(:last-of-type)::after {
          content: '•';
          padding-left: 8px;
          @include rem(font-size, 10px);
          vertical-align: 20%;
        }
      }
    }
  }
}
