/** MAIN PALETTE **/

// Grey Spectrum
$grey-50: #f4f3f1;
$grey-1: #f1f0ee;
$grey-2: #e9e7e2;
$grey-3: #dad7cf;
$grey-4: #b8b2a6;
$grey-5: #716a5b;
$grey-6: #4a453b;
$grey-7: #1c1c1c;

// Green Spectrum
$green-1: #f5f9f2;
$green-2: #0c1309;
$green-3: #adce99;
$green-4: #318500;
$green-5: #276a00;
$green-6: #1d5000;
$green-7: #1f3926;

/** EXPANDED PALETTE **/

// Amber Spectrum
$amber-1: #ffeed6;
$amber-2: #ffeed6;
$amber-3: #ffbf66;
$amber-4: #ffbf66;
$amber-5: #ff9500;
$amber-6: #ff9500;
$amber-7: #c15701;

// Red Spectrum
$red-1: #fff2f0;
$red-2: #fff2f0;
$red-3: #ffbcb2;
$red-4: #ffbcb2;
$red-5: #dc3800;
$red-6: #dc3800;
$red-7: #a61400;

// Teal Spectrum
$teal-1: #dcf9f5;
$teal-2: #dcf9f5;
$teal-3: #7de8db;
$teal-4: #26d5c0;
$teal-5: #26d5c0;
$teal-6: #008d86;
$teal-7: #008d86;

// Blue Spectrum
$blue-1: #e5f4ff;
$blue-2: #e5f4ff;
$blue-3: #b2dfff;
$blue-4: #b2dfff;
$blue-5: #72c4ff;
$blue-6: #0875c4;
$blue-7: #0875c4;

// Purple Spectrum
$purple-1: #e5f4ff;
$purple-2: #e5f4ff;
$purple-3: #b2dfff;
$purple-4: #b2dfff;
$purple-5: #72c4ff;
$purple-6: #72c4ff;
$purple-7: #0875c4;

// Orange Spectrum
$orange-1: #ffeed6;
$orange-2: #ffeed6;
$orange-3: #ffbf66;
$orange-4: #ff9500;
$orange-5: #ff9500;
$orange-6: #ff9500;
$orange-7: #c15701;

// Gold Spectrum
$gold-1: #fff9e5;
$gold-3: #ffecb1;
$gold-5: #ffd008;

//Indigo Spectrum
$indigo-7: #1b216b;
$indigo-6: #1b216b;
$indigo-5: #4553a9;
$indigo-4: #4553a9;
$indigo-3: #b1bdff;
$indigo-2: #dbe1ff;
$indigo-1: #dbe1ff;

// Blue
$blue-base: $blue-6;

// Grey
$grey-light: $grey-3;
$grey-base: $grey-4;
$grey-dark: $grey-5;
$grey-bold: $grey-6;

// Red
$red-base: $red-6;
$red-bold: $red-7;

// Yellow
$yellow-base: $amber-5;

// White
$white-base: #ffffff;
$black-base: #000000;

// Main Colors
//

$grey-main: $grey-6;

// Primary
$primary-base: $indigo-5;
$primary-dark: $indigo-7;
$primary-hover: $primary-dark;

// Secondary
$secondary-base: $grey-base;
$secondary-dark: $grey-6;

// Accent Colors
$accent-gradient: linear-gradient(
  180deg,
  #ffffff 0%,
  #ecebe7 40.1%,
  #ecebe7 100%
);

$accent-background: #ecebe7;
$accent-highlight: #fff9e5;
$accent-electric: #ffecb1;
$accent-lime: #ffd008;
$accent-green: #00857e;

// Fonts
$font-base-color: $grey-main;

$border-style: 1px solid $grey-2;
$input-border-style: 1px solid $grey-4;
$outline-style: 1px solid $indigo-3;

$icon-fill: $primary-base;
$icon-stroke: $primary-base;

:root {
  --inspira-icon-primary: $primary-base;
  --inspira-icon-secondary: $secondary-base;
  --inspira-icon-black: $black-base;
}
