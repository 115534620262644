.table-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $grey-1;
  box-shadow: 0px 1px 5px #15223229;
  padding: 15px;
  padding-right: 0;
  padding-left: 25px;
  border-radius: 20px 20px 0px 0px;
  height: 77px;
  @include text-label--medium;
  .input-container {
    display: flex;
  }
  #services-agreements {
    margin-right: 24px;
    .filter-icon {
      .filter-applied {
        svg path {
          fill: $icon-fill;
        }
      }
    }
  }

  .search-filter-container {
    .filter-btn {
      border-left: none;
      margin-right: 24px;
      .filter-icon {
        .filter-applied {
          svg path {
            fill: $icon-fill;
          }
        }
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include text-headline--small;
    bottom: 0;
    margin: 0;
    text-align: center;
  }
  .filter-btn {
    display: flex;
    align-items: center;

    svg {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
