.card-radio-group {
  padding-right: 15px;
  padding-left: 5px;

  div[role='radiogroup'] {
    margin-top: 1rem;
  }

  .input-wrapper {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    label {
      border: 1px solid $grey-1;
      border-radius: 20px;
      box-shadow: 1px 2px 4px rgba(61, 61, 58, 0.2);
      padding: 20px;
      display: flex;
      gap: 16px;
      cursor: pointer;

      .description-container {
        padding-right: 5px;
        & > p {
          &.description {
            @include text-body--large;
          }
          &.turnaround {
            @include text-body--large;
          }

          &.label {
            @include text-title--medium;
            margin-bottom: 4px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  input[type='radio'] {
    @include visually-hidden;
  }

  input[type='radio']:focus-visible + label {
    outline-offset: 4px;
    outline: 1px solid $button-hover-border-color;
    border-radius: 20px;
  }

  input[type='radio']:focus:not(:focus-visible) + label {
    outline: none;
  }

  input[type='radio']:checked + label {
    background: $indigo-1;
    border: 3px solid $primary-base;
    border-radius: 20px;
  }
}

.company-option-field {
  .input-wrapper {
    label {
      flex-direction: column;
      gap: 0;
      width: 100%;

      div.option-name-container {
        p.option-name {
          @include text-headline--small;
        }
        p.option-description {
          @include text-body--large;
        }
      }

      div.option-info-container {
        dl {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .complete-legal-plan-name .description {
            max-width: 280px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
