/*-----------------------
Flash
-----------------------*/
.flash-message-container {
  @include position(fixed, 88px 24px null null);
  z-index: 99999999;
}

.flash-message {
  @include rem(padding, 10px 10px);
  width: 80%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid;
  border-left-width: 10px;
  width: 380px;

  .dismiss {
    position: absolute;
    right: 10px;
    top: 0px;
    padding-left: 0;
    padding-right: 0;
    @include rem(font-size, 28px);
    color: $grey-5;
  }

  .flash-icon {
    margin-right: 10px;
    overflow: visible;
  }

  &__inner {
    display: flex;
  }

  &__content {
    width: 254px;
  }

  &__heading {
    @include text-title--medium;
    margin-bottom: 0;
  }

  &.success {
    background-color: $indigo-1;
    border-color: $primary-base;
  }

  &.failure {
    background-color: $red-1;
    border-color: $red-6;

    .flash-icon {
      fill: $red-6;
    }
  }

  .external-link,
  .support-email {
    color: $font-base-color;
    text-decoration: underline;
    font-size: inherit;
  }
}

//Animation

.flash-enter {
  opacity: 0.01;

  &.flash-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}

.flash-leave {
  opacity: 1;

  &.flash-leave-active {
    opacity: 0.01;
    transition: opacity 0.3s ease-in;
  }
}
