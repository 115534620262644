/*-----------------------
Page Header
-----------------------*/
$fixed-header-height: 62px;

.header {
  background-color: $indigo-7;
  height: $fixed-header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  color: #f9fafb;
  height: 62px;
  position: sticky;
  top: 0;
  z-index: 4;

  .header-right {
    display: flex;
    align-items: center;
  }

  #header-action-area {
    color: #f9fafb;
    height: 100%;
    margin: 0 auto;

    .mtc-logo {
      margin-bottom: 0;
    }

    .header-action-area-left {
      img {
        height: 42px;
        width: 42px;
        margin-left: 6px;
      }
    }

    .header-action-area-right,
    .header-action-area-left {
      display: flex;
      flex-direction: row;

      #status-select {
        color: #f9fafb;
      }

      img {
        align-items: center;
        text-align: center;
      }

      ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 26px;
        padding-bottom: 0px;
        margin-left: 70px;
        margin-top: 10px;

        li {
          a {
            color: #f9fafb;
            text-decoration: none;
            @include text-title--medium;
            padding-bottom: 18px;
            padding: 20px 5px;
          }

          a:hover {
            color: #cccccc;
          }

          a.active {
            border-bottom: 5px solid #f9fafb;
            padding-bottom: 16px;
          }
        }
      }
    }
    &.header-action-area--contact {
      width: 100%;

      .header-workflow {
        width: 100%;
        margin: 0;
        display: grid;
        grid-template-columns: minmax(224px, 1fr) 800px minmax(224px, 1fr);

        .header-contact-button-wrapper {
          grid-column: 3 / 3;
          grid-row: 1;
          position: relative;
          height: 30px;

          button {
            @include text-title--medium;
            color: $accent-electric;
            position: absolute;
            right: 24px;

            &:hover {
              color: $white-base;
            }
          }
        }

        .header-text-wrapper {
          width: 800px;
          grid-column: 2 / 3;
          grid-row: 1;
        }

        .logo-wrapper {
          min-width: 224px;
          grid-column: 1 / 3;
          grid-row: 1;
          padding-left: 24px;
        }
      }
    }
  }

  .logo-wrapper {
    min-width: 200px;
    padding-top: 4px;
  }
}

.full-layout {
  .header {
    #header-action-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 768px;
      margin: 0 auto;

      .header-action-area-left {
        width: 100%;
        align-items: center;
      }
    }
  }
}

@media (min-width: 888px) {
  .full-layout {
    .header {
      #header-action-area {
        width: calc(100vw - 120px);
      }
    }
  }
}
