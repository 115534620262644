.services-agreement-details-view {
  .header-bar {
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 0;

    .back-button-container {
      margin-bottom: 33px;
    }
  }

  .header-container {
    margin-bottom: 30px;
  }

  .header-bar-content {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: none;
    margin-bottom: 36px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .no-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 86px;

    .section-header {
      margin-bottom: 0;
    }

    p.not-provided {
      color: $grey-base;
      font-style: italic;
    }
  }

  .optional-section {
    margin: 20px 0;
  }

  .disclosure-content {
    hr {
      margin: 28px 0;
    }
  }
}
