.search-input {
  position: relative;

  input {
    @include rem(width, 310px);
    margin: 0;
    padding-left: 35px;
    border-radius: 4px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}