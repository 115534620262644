#add-new-payment-form {
  > fieldset {
    legend {
      margin-bottom: 4px;
    }
  }
  .form-column {
    display: flex;
    gap: 20px;

    fieldset {
      width: unset;
      flex-grow: 1;
    }

    .flex-shrink {
      flex: 0 1 20%;
    }
  }
}
