// Typography Mixin
// ========================================

@mixin font-circular-regular {
  font-family: CircularXXWeb-Regular, sans-serif;
  font-weight: 400;
}

@mixin font-circular-medium {
  font-family: CircularXXWeb-Medium, sans-serif;
  font-weight: 500;
}

@mixin font-circular-black {
  font-family: CircularXXWeb-Black, sans-serif;
  font-weight: 900;
}

@mixin text-headline--small {
  @include font-circular-black;
  @include rem(font-size, 18px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 18px);
}

@mixin text-headline--medium {
  @include font-circular-black;
  @include rem(font-size, 24px);
  letter-spacing: 0;
  line-height: 1.25;
  @include rem(margin-bottom, 24px);
}

@mixin text-headline--large {
  @include font-circular-black;
  @include rem(font-size, 32px);
  letter-spacing: 0;
  line-height: 1.25;
  @include rem(margin-bottom, 32px);
}

@mixin text-title--small {
  @include font-circular-medium;
  @include rem(font-size, 14px);
  letter-spacing: 0;
  line-height: 1.25;
  @include rem(margin-bottom, 14px);
}

@mixin text-title--medium {
  @include font-circular-medium;
  @include rem(font-size, 16px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 16px);
}

@mixin text-title--large {
  @include font-circular-medium;
  @include rem(font-size, 18px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 18px);
}

@mixin text-title--extra-large {
  @include font-circular-medium;
  @include rem(font-size, 24px);
  letter-spacing: 0;
  line-height: 1.25;
  @include rem(margin-bottom, 24px);
}

@mixin text-label--small {
  @include font-circular-medium;
  @include rem(font-size, 14px);
  letter-spacing: 0.25px;
  line-height: 1.5;
}

@mixin text-label--medium {
  @include font-circular-medium;
  @include rem(font-size, 16px);
  letter-spacing: 0.25px;
  line-height: 1.5;
}

@mixin text-body--small {
  @include font-circular-regular;
  @include rem(font-size, 12px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 12px);
}

@mixin text-body--medium {
  @include font-circular-regular;
  @include rem(font-size, 14px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 14px);
}

@mixin text-body--large {
  @include font-circular-regular;
  @include rem(font-size, 16px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 16px);
}
@mixin link-body--sm {
  @include font-circular-medium;
  @include rem(font-size, 12px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 12px);
}
