.all-plans {
  .header-bar {
    padding-bottom: 40px;

    &__title {
      margin-bottom: 0;
    }
  }

  .header-bar-content {
    padding: 0 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 120px);
    z-index: 2;
    border-bottom: 2px solid $grey-2;   
  }

  .services-agreements-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

  }
}
