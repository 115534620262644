/*-----------------------
Layout
-----------------------*/

.full-layout {
  background: #ffffff;
  background: linear-gradient(0deg, #ecebe7 0%, #ffffff 100%);
}

#main-content {
  padding: 62px 0 0 0;
  margin: 0 auto;
  width: 768px;

  .back-button-container {
    a.button-text {
      margin: 0;
    }
  }
}

.content-wrapper {
  &.all-plans {
    // #main-content top padding + .header height + .footer top margin
    min-height: calc(100vh - 62px - 62px - 50px);
  }
}

.unauthenticated-content {
  margin-top: 50px;
}

.profile-layout,
.company-layout {
  display: grid;
  grid-template-columns: 19% 81%;
  margin: 0;

  aside {
    @include rem(margin-right, 20px);

    ul {
      display: flex;
      flex-direction: column;
      padding: 0 0 1px;

      li {
        height: 55px;
        box-sizing: border-box;
        padding: 0;
        margin-bottom: 0;
        overflow: hidden;

        &:first-of-type a {
          border-top-left-radius: $border-radius;
        }

        &:last-of-type a {
          border-bottom-left-radius: $border-radius;
        }

        svg {
          width: 17px;
          height: 20px;
          overflow: visible;
          position: relative;
          left: -10px;

          path,
          circle {
            stroke: $icon-stroke;
          }
        }

        a {
          height: 56px;
          display: flex;
          align-items: center;
          text-decoration: none;
          @include text-body--medium;
          padding: 20px 0 20px 20px;
          border-left: 3px solid transparent;
        }

        a.active {
          border-left: 3px solid $primary-base;
          color: $primary-base;

          svg {
            g {
              stroke: $primary-base;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 888px) {
  #main-content {
    width: calc(100vw - 120px);
  }
}

@media (max-width: 1200px) {
  .profile-layout,
  .company-layout {
    .profile-links,
    .company-link {
      padding: 20px 50px 11px 0;
    }
  }
}
