#timeout-modal {
  @include rem(width, $modal-width);

  .timeout-modal-actions {
    border-top: $border-style;
    display: flex;
    justify-content: flex-end;
    padding-top: 1.375rem;
    margin-top: 1.375rem;
  }

  .button-primary {
    min-width: auto;
  }
}
