.search-history {
  .header-bar {
    padding-bottom: 40px;
  }

  .header-bar-content {
    padding: 0 0 25px;
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 120px);
    z-index: 2;
  }

  .search-orders-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      margin-bottom: 0;
    }

    .filter-btn {
      padding-right: 0;
      display: flex;
      flex-direction: row;
      gap: 3px;
      align-items: center;
    }

    button {
      display: flex;
      margin: 0;
      vertical-align: middle;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .search-order-download-button {
    display: flex;
    margin: 0;
    vertical-align: middle;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.search-history-btn.button-primary,
a.search-history-btn.button-primary {
  min-height: 40px;
  display: grid;
  align-items: center;
}
