.is-nowrap {
  display: inline-block;
  white-space: nowrap;
  padding-right: 4px;
}

.is-flex {
  @include is-flex;
}

.is-flex-between {
  @include is-flex-between;
}

.is-marginless {
  margin: 0 !important;
}
