.confirm-order-details {
  .full-row {
    grid-column: 1 / -1;
  }

  dl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .user-details dd {
      margin-bottom: 6px;
    }
  }
}
.e-signature,
.payment-confirmation {
  .confirmation-checkbox-container {
    .checkbox {
      label {
        @include text-label--small;
      }
    }
  }
}

#e-signature-header-container {
  h3 {
    border: 0;
    padding-top: 52px;
  }
}

#payment-details-header {
  display: flex;
  vertical-align: auto;
  border-top: $border-style;

  h3 {
    border-top: 0;
  }

  button {
    display: inline-block;
    margin-left: 12px;
    padding: 12px 0;
    height: 20px;
  }
}

.replace-payment-button {
  margin-top: 4px;
  display: flex;
  color: $primary-base;
  @include text-label--small;
}

.remove-payment-modal {
  width: 400px;
}
