.participant-summary-table {
  h4 {
    @include text-title--medium;
  }

  th,
  td {
    border-bottom: none;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  thead {
    border-bottom: none;
  }

  tbody {
    tr {
      border-top: $border-style;

      td {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      &:last-of-type { // summary row
        td {
          @include text-label--small;
        }
      }
    }
  }

  tr:last-of-type {
    td {
      padding-bottom: 0;
    }
  }

  tr th,
  td {
    &:first-of-type {
      max-width: 11.25rem;
    }
  }
}
