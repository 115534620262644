.icon-info {
  path:last-of-type {
    fill: $icon-fill;
  }
}

.icon-download {
  g[data-name='Group 10948'] {
    path {
      fill: $primary-base;
    }
  }
}
