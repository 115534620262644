.complete-search-agreement-view {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 800px;

  .complete-search-agreement-form-container {
    .form-masthead {
      p {
        padding: 0 60px;
      }
    }

    .form-column {
      fieldset {
        @include rem(margin-bottom, 48px);

        &:last-child {
          @include rem(margin-bottom, 40px);
        }
      }
    }

    .form-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
      column-gap: 32px;
    }
    .scrollable-container {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 223px;
      border: 1px solid;
      border-color: $grey-6;
      border-radius: 4px;
      padding: 16px;
      margin-bottom: 40px;
      &.is-error {
        border-color: $red-base;
      }
    }
    .form-section {
      .header-bar-content {
        display: flex;
        align-items: baseline;
        border-top: $border-style;
      }
    }
  }

  .signature-details-container {
    dl {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 34px;
      margin-bottom: 26px;
    }
  }

  .search-services-agreement-language {
    margin-bottom: 40px;
  }
}
