.personal-details-container {
  padding-bottom: 36px;
  dl {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    column-gap: 50px;
    row-gap: 50px;
  }

  dd {
    margin-bottom: 0;
  }

  .personal-details-full-row {
    grid-column: 1 / -1;
  }

  .personal-details-header:not(:first-of-type) {
    padding-top: 24px;
  }
}

.personal-layout {
  hr {
    margin: 28px 0;
  }
}

.personal-details-container:not(:last-of-type) {
  border-bottom: 1px solid $grey-2;
}

@media (max-width: 1200px) {
  .personal-details-container {
    width: inherit;
    grid-template-columns: repeat(2, 1fr);
  }
}

.personal-details-column {
  dd:last-child {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  dd:last-child:hover {
    overflow: visible;
    white-space: normal;
    display: block;
  }
}

.personal-website {
  color: $primary-base;
  text-decoration: none;
}

.personal-details-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  + div.card {
    margin: 0;
    padding: 24px;
  }
}
