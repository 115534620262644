.plan-confirmation {
  h2 {
    @include rem(padding-bottom, 14px);
  }

  .plan-information {
    h2 {
      border-bottom: $border-style;
    }

    .plan-information-table-container {
      margin-bottom: 20px;

      .table-header,
      .table-row {
        display: grid;
        align-items: center;
        grid-template-columns: 28% 36% 36%;
      }

      .table-header {
        .table-cell {
          padding: 12px;
          width: 225px;
          margin-bottom: 0;
        
          &:first-child {
            padding-left: 0;
          }
        }
      }

      .table-row {
        border-top: $border-style;

        .table-cell {
          padding: 12px; 
          vertical-align: middle;
          display: table-cell;
          margin-bottom: 0;
        
          &:first-child {
            padding-left: 0;

            span {
              vertical-align: middle;
            }
          }
        }

        fieldset {
          margin-bottom: 0;
        }

        select {
          border: none;
        }
      }

    }
  }
}
