#upload-services-agreement-modal {
  @include rem(width, 776px);
  max-width: 100%;
  max-height: fit-content;

  .plan-details-container {
    border-top: $border-style;
    border-bottom: $border-style;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  dl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    div:last-child {
      margin-bottom: 0;
    }

    .plan-details-column {
      @include rem(margin-bottom, 20px);
    }
  }
}
