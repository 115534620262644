.error-state {
  text-align: center;
  @include center;
  max-width: 750px;
  
  &__title {
    margin-top: 20px;
  }

  &__description {
    @include text-body--large;
    a {
      font-size: inherit;
    }
  }

  p {
    a {
      font-size: inherit;
    }
  }

  .button-primary {
    margin-top: 10px;
  }
}