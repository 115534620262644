.idle-modal-actions-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  #log-out-btn {
    color: $primary-base;
    padding: 0 10px;
  }
}
