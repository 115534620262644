.filter-formik-container {
  position: relative;
  z-index: 4;
}

.filter-dialog-container {
  width: 568px;
  top: -6px;
  right: 45px;
  padding: 24px;
  position: absolute;
  background: $white-base 0% 0% no-repeat padding-box;
  padding: 24px;
  box-shadow: 0px 3px 6px #0000001a;
  border: $border-style;
  @include border-radius;
  opacity: 1;
  z-index: 3;

  .filter-row {
    display: flex;
    border-bottom: $border-style;
    align-items: flex-start;
    position: relative;
    padding: 24px 0;

    & > fieldset:first-of-type {
      display: flex;
      justify-content: flex-start;
      margin: 0;
      width: 100%;
      position: relative;

      &.CheckboxGroup {
        margin-bottom: -24px;
      }

      div {
        div {
          @include border-radius($input-border-radius);
        }
      }
    }

    & > fieldset {
      > :first-child {
        flex-grow: 1;
        max-width: 30%;
      }

      > :last-child {
        flex-grow: 1;
      }
    }

    & > fieldset > span > label,
    legend,
    .fake-legend {
      width: 150px;
    }

    & > .CheckboxGroup {
      flex-wrap: wrap;
      .checkbox {
        width: 35%;

        &:nth-child(2n + 4) {
          margin-left: 30%;
        }
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    #filter-date-range {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      max-width: 70%;

      .react-datepicker__close-icon::after {
        top: 19px;
      }

      fieldset {
        width: auto;
        flex-grow: 1;
        margin-bottom: 0;
        height: 38px;

        input {
          margin-top: 0;
        }
      }

      #date-separator {
        @include rem(font-size, 24px);
        display: flex;
        align-items: center;
      }

      input {
        background: url('../images/calendar.svg') no-repeat;
        background-position: 4px;
        background-size: 20px;
        padding-left: 28px;
        font-style: normal;
      }
    }
  }

  .filter-actions-container {
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    border-bottom: none;

    .filter-actions {
      margin: 0;

      #filter-btn-clear {
        color: $red-bold;
        &:hover {
          background-color: $red-2;
        }
      }

      #filter-btn-cancel {
        margin-right: 12px;
      }
    }
  }
}
