.header-workflow {
  width: 1250px;
  margin: 0 auto;
  padding-top: 13px;
  display: flex;
  align-items: center;

  h1 {
    color: $white-base;
    min-width: 746px;
    margin-bottom: 0;
  }

  .header-text-wrapper {
    display: flex;
    width: 850px;
    justify-content: space-between;
  }

  .action-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .vl {
      border-left: $border-style;
      height: 30px;
      margin-left: 12px;
      margin-right: 12px;
    }

    a {
      line-height: 1;
      margin-bottom: 0;
    }
  }
}
