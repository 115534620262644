.header-bar {
  .page-title {
    @include text-headline--medium;
    margin-bottom: 0;
    padding: 0;
  }
}
#search-title {
  padding-bottom: 0;
  h1 {
    padding-top: 5px;
  }
}
