.confirmed-search-form {
  .radio-container-input {
    .input-wrapper {
      display: inline-flex;
      align-items: baseline;
    }
    .input-wrapper label {
      margin-left: 10px;
      margin-right: 15px;
    }
    label {
      @include text-body--large;
    }
    p {
      @include text-title--medium;
    }
  }
}
